























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "EmptyPage"
})
export default class EmptyPage extends Vue {
  @Prop({ required: true }) image!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ default: "" }) subTitle!: string;
  @Prop({ default: "70px" }) imageWidth!: string;
}
